.light{
  --text-color: var(--black);
  --bg-color: var(--white);
}

.dark{
  --text-color: var(--black);
  --bg-color: var(--ice);
}

.standard{
  --height-carousel: 594px;
}

.compact{
  --height-carousel: 380px;
}

.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  background: var(--bg-color);
  align-items: center;
  display: flex;
  height: var(--height-carousel);
}

.carouselLightbox {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  background: var(--bg-color);
  grid-column: 1 / full-end !important;
}

.carouselContainer {
  display: flex;
  gap: var(--spacing-16);
  grid-column: full-start / full-end;
}

.carouselWrapper{
  grid-column: full-start / full-end !important;
}
.carouselSlide {
  position: relative;
  display: block;
  max-width: 95%;
  width: fit-content;
  height: var(--height-carousel);
  flex: 0 0 auto;
}

.carouselSlide img{
  height: 100%;
}

.navigationContainer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.carouselNav {
  --size-icon: 2rem;
  display: flex;
  flex-flow: row nowrap;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-40);
}

.carouselButtonIconPrev{
  transform: rotate(180deg);
}

@media (max-width: 1200px) {
  .standard{
    --height-carousel: 440px;
  }
  
  .compact{
    --height-carousel: 318px;
  }
}
@media (max-width: 480px) {
  .standard{
    --height-carousel: 300px;
  }
  
  .compact{
    --height-carousel: 175px;
  }
}